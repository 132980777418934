import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { ReactElement } from 'react'

const useStyles = makeStyles({
  wrapper: {
    paddingBottom: '5px',
    marginBottom: '15px',
    display: 'flex',
    borderBottom: '1px groove black',
    width: '400px',
    ['@media (max-width: 1010px)']: {
      border: 'none',
      justifyContent: 'center',
      width: '100%'
    }
  },
  name: {
    minWidth: '200px',
    ['@media (max-width: 1010px)']: {
      minWidth: '150px'
    }
  },
  data: {
    paddingLeft: '10px'
  }
})
export interface Consultation {
  name: string
  place: string
  platform: string
  time1: string
  time2: string
  time3: string
}

interface Props {
  consultation: Consultation
}

export const ConsultationComponent = ({
  consultation
}: Props): ReactElement => {
  const styles = useStyles()
  const { name, place, platform, time1, time2, time3 } = consultation
  const platformFormated = platform ? `/${platform}` : ''
  return (
    <div className={styles.wrapper}>
      <div className={styles.name}>
        <b>{name}</b>
        <div>{`${place}${platformFormated}`}</div>
      </div>
      <div className={styles.data}>
        <div>{time1}</div>
        <div>{time2}</div>
        <div>{time3}</div>
      </div>
    </div>
  )
}
