import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { Consultation, ConsultationComponent } from './ConsultationComponent'

const useStyles = makeStyles({
  wrapper: {
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  firstColumn: {
    paddingLeft: '20px'
  },
  secondColumn: {
    paddingLeft: '20px'
  }
})
interface Props {
  consultations: Array<Consultation>
}

export const ConsultationsComponent = ({ consultations }: Props) => {
  const styles = useStyles()
  const isMobile = useMediaQuery('(max-width: 1010px)')
  if (isMobile) {
    return (
      <div>
        {consultations.map(consultation => (
          <ConsultationComponent consultation={consultation} />
        ))}
      </div>
    )
  }
  const halfLenght = Math.ceil(consultations.length / 2)
  const firstHalf = consultations.slice(0, halfLenght)
  const secondHalf = consultations.slice(halfLenght, consultations.length)
  return (
    <div className={styles.wrapper}>
      <div>
        {firstHalf.map((consultation: Consultation) => {
          return <ConsultationComponent consultation={consultation} />
        })}
      </div>
      <div className={styles.secondColumn}>
        {secondHalf.map((consultation: Consultation) => {
          return <ConsultationComponent consultation={consultation} />
        })}
      </div>
    </div>
  )
}
